import React from "react"
import { graphql } from "gatsby"
// import { withPreview } from 'gatsby-source-prismic'
import Layout from "../../components/Layout"
import Seo from "../../components/Seo"
import SliceZone from "../../components/SliceZone"
import PageTitleIcon from "../../components/PageTitleIcon"

const isBrowser = typeof window !== "undefined"

const Page = ({ location, data }) => {

  if (isBrowser) {
    
    if (!data) return null
    
    const url = location.href ? location.href : '';
    const { type, uid } = data.allPrismicPage.edges[0].node
    const seo = data.allPrismicPage.edges[0].node.data.body1[0].primary
    const document = data.allPrismicPage.edges[0].node.data
    const title = document.title.text
    const { icon } = document

    return (
      <div>
        <Layout pageType={type} uid={uid}>
          <Seo title={title} seo={seo} url={url} />
          <PageTitleIcon title={title} icon={icon.url} />
          <SliceZone sliceZone={document.body} />
        </Layout>
      </div>
    )
  }

  return <div> </div>
}

export const query = graphql`
  query PageQuery($uid: String) {
    allPrismicPage(filter: { uid: { eq: $uid } }) {
      edges {
        node {
          uid
          type
          data {
            title {
              text
            }
            icon {
              url
              alt
            }
            body {
              ... on PrismicPageDataBodyDocumentList {
                slice_type
              }
              ... on PrismicPageDataBodyDocumentTabs {
                slice_type
              }
              ... on PrismicPageDataBodyTabs {
                slice_type
              }
              ... on PrismicPageDataBodyQuickLinks {
                slice_type
                items {
                  link_title
                  link {
                    url
                    uid
                    slug
                  }
                  link_icon {
                    alt
                    url
                  }
                }
              }
              ... on PrismicPageDataBodyIframe {
                slice_type
                primary {
                  iframe_url
                }
              }
              ... on PrismicPageDataBodySharePriceHighlights {
                slice_type
              }
              ... on PrismicPageDataBodySharePriceTable {
                slice_type
                primary {
                  share_price_table_title {
                    text
                  }
                }
              }
              ... on PrismicPageDataBodyButtonBlock {
                slice_type
                primary {
                  button_copy
                  button_link {
                    uid
                    url
                    target
                  }
                }
              }
            }
            body1 {
              ... on PrismicPageDataBody1SeoPages {
                primary {
                  description
                  og_title
                  og_description
                  og_image {
                    url
                  }
                  og_image_alt
                  twitter_image {
                    url
                  }
                  twitter_image_alt
                } 
              }
            }
          }
        }
      }
    }
  }
`

// export default withPreview(Page)
export default Page
