import React from "react"
import { Fade } from "react-awesome-reveal"

const PageTitleIcon = ({ title, icon }) => {
  return (
    <Fade triggerOnce>
      <section className="py-12 border-b lg:pb-20 border-grey-25">
        <div className="container-fluid">
          <div className="flex">
            <img
              className="mr-6"
              src={icon}
              alt={title}
              width="40px"
              height="40px"
            />
            <h1 className="hero">{title}</h1>
          </div>
        </div>
      </section>
    </Fade>
  )
}

export default PageTitleIcon
